<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h4>Add Organisations</h4>
            </b-col>
          </b-row>

          <b-row class="h-100" v-if="state !== 'show'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row>

          <b-form v-if="state === 'show'">
            <b-row>
              <b-col cols="4">
                <label>Name <span class="text-red">*</span></label>
                <b-form-input v-model="orgData.name" @blur="orgData.name.$touch()"></b-form-input>

              </b-col>
              <b-col cols="4">
                <label>Logo</label>

                <b-form-file v-model="file">
                  <template slot="file-name" slot-scope="{ names }">
                    <b-badge variant="dark">{{ names[0] }}</b-badge>
                    <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                      + {{ names.length - 1 }} More files
                    </b-badge>
                  </template>
                </b-form-file>
              </b-col>

            </b-row>

            <hr class="mx-3">
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button variant="outline-red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                  </div>
                  <div>
                    <b-button variant="primary" squared @click="submit()" class="ml-2">Save</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<style>
.primary {
  background-color: #ffcb14;
  color: #222222;
}

.primary:hover {
  color: #fff;
}

.page-item.active .page-link {
  z-index: 3;
  color: #222222;
  background-color: #ffcb14;
  border-color: #ffcb14;
}

</style>
<script>
import {
  mapActions,
  mapMutations
} from "vuex";
import {
  required,
} from "vuelidate/lib/validators";
import api from "../../../api";
import axios from "axios";

export default {
  name: "createOrganisations",
  data: () => ({
    file: null,
    state: 'show',
    orgData: {
      Id: 0,
      name: null,
      logo: null,

    }
  }),
  created() {
    this.setBreadcrumb([{
      text: 'User'
    },
      {
        text: 'Add New Organisation'
      },
    ])
  },
  methods: {
    ...mapActions(["createUser", "getUserRoles"]),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    ...mapActions('notificationService', ['addWarningMessage']),

    submitOrganisation() {
        const s = (data) => {   
          this.$router.push ({path: '/admin/ManageOrganisations/SearchOrganisation'})      
          console.log( data);
        };

        const e = (msg) => {
          console.log(msg);
        };
         
        api.CreateOrganisation(this.orgData, s, e)
    },

    submit() {
      // Why is this in this order? 
      // Surely, the correct order would be to create an organisation first, then upload the file? 
      // Why not make the creation of an organisation with it's logo? Why two calls to perform one end result?

      if (this.file == null)
      {
         this.orgData.logo =  '';
        const s = (data) => {
          console.log(data);
          this.$router.push({path: '/admin/ManageOrganisations/SearchOrganisation'});
        };

        const e = (msg) => {
          // TODO: Error message needed.
          console.log(msg);
        };

        api.CreateOrganisation(this.orgData, s, e);
        return;
      }

      const formData = new FormData();
      formData.append("file",  this.file);
      console.log(this.file)
      axios.post(api.Url + "/Cdn", formData, {
        headers: {
          Authorization : `Bearer ${localStorage.getItem("api_Key")}`,
          'Content-Type': 'multipart/form-data'
        }
      }).then((resp) => {
        console.log(this.file)
        this.orgData.logo =  "/Cdn/Get/" + resp.data + "/" + this.file.name;
        console.log(this.orgData.logo)
        const s = (data) => {
          console.log(data);
          this.$router.push({path: '/admin/ManageOrganisations/SearchOrganisation'})
        };

        const e = (msg) => {
          console.log(msg);
          this.makeToast(msg);
        };

        api.CreateOrganisation(this.orgData, s, e)

      }).catch((error) => {
        console.error(error);
        this.makeToast(error.response.data);
      });


    },

    makeToast(message) {
            this.$bvToast.toast(`${message}`, {
                title: 'Something went wrong.',
                autoHideDelay: 5000,
                appendToast: false
            })
        },

    goBackToSearch() {
      this.$router.back()
    },
  },
  computed: {},
  validations: {
    orgData: {
      name: {
        required
      },

    },
  },
}
</script>

<style scoped>

</style>